import React from 'react';
import PropTypes from 'prop-types';

const TeamList = ({ teams }) => {
    if (!teams.length) {
        return (
            <span className="block uppercase font-bold tracking-wide text-sm text-gray-light text-center">
                No teams have earned this honor
            </span>
        );
    }

    return (
        <div className="mt-4 -ml-8 flex flex-wrap justify-center">
            {teams.map(team => (
                <div className="pl-8 pt-4 w-full md:w-1/2 lg:w-1/3" key={team.id}>
                    <div className="shadow-lg rounded-lg py-6 px-8 flex text-display text-base-display text-gray-dark bg-white">
                        <span className="block truncate w-4/5">{team.school}</span>
                        <span className="block w-1/5">{team.state}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

TeamList.propTypes = {
    teams: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            school: PropTypes.string,
            state: PropTypes.string,
        })
    ),
};

TeamList.defaultProps = {
    teams: [],
};

export default TeamList;
