import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import cx from 'classnames';
import debounce from 'lodash/debounce';

import { apiUrl } from '../../utils';
import Result from './Result';
import Controls from '../Leaderboard/Controls';

class Leaderboard extends Component {
    static propTypes = {
        title: PropTypes.string,
        query: PropTypes.string,
        state: PropTypes.string,
        pageSize: PropTypes.number,
        showSearch: PropTypes.bool,
        showStateSelect: PropTypes.bool,
        showLoadMore: PropTypes.bool,
    };

    static defaultProps = {
        title: 'Student Leaderboard',
        query: '',
        state: null,
        pageSize: 10,
        showSearch: false,
        showStateSelect: false,
        showLoadMore: false,
    };

    /* eslint-disable react/destructuring-assignment */
    state = {
        page: 0,
        results: [],
        isLoading: true,
        canLoadMore: true,
        query: this.props.query,
        stateFilter: this.props.state,
    };
    /* eslint-enable react/destructuring-assignment */

    componentDidMount() {
        this.fetchResults();
    }

    handleLoadMore = () => {
        this.setState(prev => ({ page: prev.page + 1 }), () => this.fetchResults());
    };

    handleQueryChange = ({ target }) =>
        this.setState(() => ({ query: target.value }), this.handleSubmit);

    handleStateFilterChange = value =>
        this.setState(() => ({ stateFilter: value }), this.handleSubmit);

    // eslint-disable-next-line react/sort-comp
    handleSubmit = debounce(
        () => this.setState(() => ({ results: [], page: 0 }), () => this.fetchResults()),
        250,
        { trailing: true }
    );

    async fetchResults() {
        try {
            const { pageSize } = this.props;
            const { query, stateFilter, page, results } = this.state;

            const params = {
                limit: pageSize,
                skip: page * pageSize,
            };

            if (query) params.name = query;
            if (stateFilter) params.state = stateFilter;

            this.setState(() => ({ isLoading: true }));

            const leaderboard = await $.get(apiUrl(`/fundraiser-leaderboard?${$.param(params)}`));

            this.setState(() => ({
                isLoading: false,
                results: page > 0 ? [...results, ...leaderboard] : leaderboard,
                canLoadMore: leaderboard.length === pageSize,
            }));
        } catch (err) {
            console.log('uh oh', err);
            this.setState(() => ({ isLoading: false }));
        }
    }

    render() {
        const { results, canLoadMore, isLoading, query, stateFilter } = this.state;
        const { title, showLoadMore, showSearch, showStateSelect } = this.props;
        const shouldShowMore = canLoadMore && showLoadMore && !isLoading;
        const showControls = showSearch || showStateSelect;
        const noResults = !isLoading && !results.length;

        return (
            <div>
                <div className="mb-8 md:mb-16 flex flex-col lg:flex-row items-center justify-between">
                    <div className="flex-1">
                        <h1 className="text-2xl-display text-white font-bold text-display text-center lg:text-left">
                            {title}
                        </h1>
                    </div>
                    {showControls && (
                        <div className="flex-1 w-full mt-8 lg:mt-0">
                            <Controls
                                label="Where do you stand?"
                                query={query}
                                onQueryChange={this.handleQueryChange}
                                stateFilter={stateFilter}
                                onStateFilterChange={this.handleStateFilterChange}
                                onSubmit={this.handleSubmit}
                                showSearch={showSearch}
                                showStateSelect={showStateSelect}
                            />
                        </div>
                    )}
                </div>
                {noResults && (
                    <p className="my-12 text-center text-display text-lg-display text-gray-light">
                        No results found.
                    </p>
                )}
                {!!results.length && (
                    <div>
                        <div className="flex uppercase font-bold tracking-wide text-xs text-white">
                            <div className="hidden md:block w-24">Rank</div>
                            <div className="flex-4 px-8">Student</div>
                            <div className="hidden md:block flex-no-shrink flex-1 px-8 text-center">
                                State
                            </div>
                            <div className="flex-no-shrink flex-1 px-8 text-center">Raised</div>
                            <div className="flex-no-shrink px-8">
                                <i className="fal fa-plus-circle fa-fw fa-2x text-xl invisible" />
                            </div>
                        </div>
                        {results.map((result, i) => (
                            <Result key={result.username} rank={i + 1} {...result} />
                        ))}
                    </div>
                )}
                <div className="mt-8 text-center">
                    {isLoading && (
                        <span className="text-display text-lg-display text-gray-light inline-block py-4">
                            <i
                                className={cx('far fa-spinner fa-spin text-lg mr-4', {
                                    hidden: !isLoading,
                                })}
                            />{' '}
                            Loading
                        </span>
                    )}
                    {shouldShowMore && (
                        <button
                            className={cx(
                                'text-display text-base-display rounded px-8 py-4 bg-yellow transition shadow hover:shadow-lg text-white w-full md:w-auto md:inline-block',
                                {
                                    'opacity-60 pointer-events-none': isLoading,
                                }
                            )}
                            onClick={this.handleLoadMore}
                            type="button"
                        >
                            View More Students
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default Leaderboard;
