import React from 'react';
import PropTypes from 'prop-types';

const ProcessSimple = ({ headline, content, buttonLabel, onButtonClick }) => (
    <div className="flex flex-col flex-1 mt-4 text-center">
        <div className="flex flex-col flex-1 overflow-auto">
            <div className="flex-grow flex-no-shrink flex flex-col justify-center">
                <div>
                    <h1 className="text-display font-normal text-2xl-display mb-8 text-yellow">
                        {headline}
                    </h1>
                    <p>{content}</p>
                </div>
            </div>
        </div>
        <div className="flex-no-shrink mt-16 ">
            <button className="btn btn--yellow block w-full" type="button" onClick={onButtonClick}>
                {buttonLabel}
            </button>
        </div>
    </div>
);

ProcessSimple.propTypes = {
    headline: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
};

ProcessSimple.defaultProps = {};

export default ProcessSimple;
