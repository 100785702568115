import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Video from './Video';

/* eslint-disable react/no-danger */
const ProcessOverview = ({
    onContinue,
    moreLabel,
    moreUrl,
    continueLabel,
    title,
    content,
    video,
}) => (
    <div className="flex flex-col flex-1 mt-4">
        <div className="flex-grow lg:flex lg:-ml-24 justify-center overflow-auto">
            {video && (
                <div className="mb-8 lg:mb-0 lg:pl-24 lg:min-w-1/2 lg:flex-1">
                    <Video url={video} className="shadowed" />
                </div>
            )}
            <div
                className={cx('text-center md:min-w-1/2 lg:flex-1 lg:pl-24', {
                    'lg:text-left': video,
                })}
            >
                <h1 className="text-display font-normal text-2xl-display mb-4 text-yellow">
                    {title}
                </h1>
                <div
                    className="styled-content text-white text-base leading-loose"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </div>
        </div>
        <div className="flex-no-shrink mt-12 md:-ml-24 flex flex-col-reverse md:flex-row text-center">
            {moreUrl && (
                <div className="md:pl-24 md:min-w-1/2 md:flex-1">
                    <a className="btn btn--yellow btn--outline block w-full" href={moreUrl}>
                        {moreLabel}
                    </a>
                </div>
            )}
            <div className="mb-4 md:mb-0 md:pl-24 md:min-w-1/2 flex-1">
                <button className="btn btn--yellow block w-full" type="button" onClick={onContinue}>
                    {continueLabel}
                </button>
            </div>
        </div>
    </div>
);

ProcessOverview.propTypes = {
    moreUrl: PropTypes.string.isRequired,
    moreLabel: PropTypes.string.isRequired,
    onContinue: PropTypes.func.isRequired,
    continueLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    video: PropTypes.string.isRequired,
};

ProcessOverview.defaultProps = {};

export default ProcessOverview;
