var Handlebars = require('/mnt/c/Users/joshl/Sites/touchdownsagainstcancer.com/htdocs/wp-content/themes/custom-theme/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"c-leaderboard__row\">\r\n        <div class=\"c-leaderboard__column\">"
    + alias4((helpers.add || (depth0 && depth0.add) || alias2).call(alias1,(data && data.index),(depths[1] != null ? depths[1].startIndex : depths[1]),{"name":"add","hash":{},"data":data}))
    + "</div>\r\n        <div class=\"c-leaderboard__column\">"
    + alias4(((helper = (helper = helpers.teamName || (depth0 != null ? depth0.teamName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"teamName","hash":{},"data":data}) : helper)))
    + "</div>\r\n        <div class=\"c-leaderboard__column\">"
    + alias4(((helper = (helper = helpers.state || (depth0 != null ? depth0.state : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"state","hash":{},"data":data}) : helper)))
    + "</div>\r\n        <div class=\"c-leaderboard__column\">"
    + alias4((helpers.or || (depth0 && depth0.or) || alias2).call(alias1,(depth0 != null ? depth0.displayValue : depth0),"-",{"name":"or","hash":{},"data":data}))
    + "</div>\r\n    </a>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div>No results</div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.items : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});