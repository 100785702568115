import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { pledgeItUrl } from '../../utils';

// "contributors": 20,
// "contributions": 20,
// "slug": "tac18-pembroke-ga-bryan-county-redskins",
// "name": "Bryan County Touchdowns Against Cancer 2018",
// "school": "Bryan County",
// "city": "Pembroke",
// "state": "GA",
// "mascot": "Redskins",
// "mascot_url": null,
// "url": "https://pledgeit.org/tac18-pembroke-ga-bryan-county-redskins",
// "id": "5b4f91475325fd07e431c383"

const ContributionLeaderboardResult = ({ rank, contributions, school, state, url }) => (
    <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center rounded-lg bg-white shadow-lg mt-4 text-display text-base-display transition text-gray-dark cursor-pointer group overflow-hidden font-thin"
    >
        <div className="transition self-stretch w-24 justify-center items-center text-white text-lg-display hidden md:flex bg-yellow group-hover:bg-gray">
            {rank}
        </div>
        <div className="flex-4 px-8 py-6 truncate">
            {school}
            <span className="block font-sans font-bold text-2xs text-gray-light mt-2">{state}</span>
        </div>
        <div className="flex-no-shrink flex-1 px-8 py-7 text-center">
            {numeral(contributions).format('0,0')}
        </div>
        <div className="flex-no-shrink px-8 py-7">
            <i className="transition fal fa-arrow-circle-right fa-fw fa-2x text-xl text-yellow group-hover:text-gray" />
        </div>
    </a>
);

ContributionLeaderboardResult.propTypes = {
    rank: PropTypes.number.isRequired,
    contributions: PropTypes.number.isRequired,
    school: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default ContributionLeaderboardResult;
