import numeral from 'numeral';

export default {
    currency: x => numeral(x).format('$0,0'),

    gt: (x, y) => x > y,
    gte: (x, y) => x >= y,
    eq: (x, y) => x === y,
    ne: (x, y) => x !== y,
    lt: (x, y) => x < y,
    lte: (x, y) => x <= y,

    or: (x, y) => x || y,
    and: (x, y) => x && y,

    add: (x, y) => x + y,
    subtract: (x, y) => x - y,
    multiply: (x, y) => x * y,
    divide: (x, y) => x / y,
    inc: x => Number(x) + 1,

    string: x => String(x),
    bool: x => Boolean(x),
    number: x => Number(x),
};
