import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import numeral from 'numeral';

class LeaderboardResult extends Component {
    static propTypes = {
        rank: PropTypes.number.isRequired,
        school: PropTypes.string.isRequired,
        mascot: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        results: PropTypes.number.isRequired,
        school_id: PropTypes.string.isRequired,
        estimated_amount_raised: PropTypes.string.isRequired,
    };

    static defaultProps = {};

    state = { isOpen: false };

    toggleOpen = () => this.setState(prev => ({ isOpen: !prev.isOpen }));

    render() {
        const { isOpen } = this.state;
        const {
            rank,
            school,
            mascot,
            state,
            results,
            url,
            school_id: schoolId,
            estimated_amount_raised: amountRaised,
        } = this.props;

        return (
            <div>
                <div
                    className={cx(
                        'flex items-center rounded-lg bg-white shadow-lg mt-4 text-display text-base-display transition text-gray-dark cursor-pointer group overflow-hidden'
                    )}
                    onClick={this.toggleOpen}
                >
                    <div
                        className={cx(
                            'transition self-stretch w-24 justify-center items-center text-white text-lg-display hidden md:flex',
                            {
                                'bg-yellow group-hover:bg-gray': !isOpen,
                                'bg-gray-dark': isOpen,
                            }
                        )}
                    >
                        {rank}
                    </div>
                    <div className="flex-4 px-8 py-6 truncate">
                        {school} {mascot}
                        <span className="block font-sans font-bold text-2xs text-gray-light mt-2">
                            {state}
                        </span>
                    </div>
                    <div className="hidden md:block flex-no-shrink flex-1 px-8 py-7 text-center">
                        {results}
                    </div>
                    <div className="flex-no-shrink flex-1 px-8 py-7 text-center">
                        {numeral(amountRaised).format('$0,0')}
                    </div>
                    <div className="flex-no-shrink px-8 py-7">
                        <i
                            className={cx('transition fal fa-plus-circle fa-fw fa-2x text-xl', {
                                'text-yellow group-hover:text-gray rotate-0': !isOpen,
                                'text-gray-dark rotate-45': isOpen,
                            })}
                        />
                    </div>
                </div>
                <div
                    className={cx({
                        'max-h-0 overflow-hidden transition-fast-out': !isOpen,
                        'max-h-3/4-screen transition-fast-in': isOpen,
                    })}
                >
                    <div
                        className={cx('py-4 px-8 lg:px-0 text-center', {
                            'opacity-0 transition-fast-out': !isOpen,
                            'opacity-100 transition-fast-in': isOpen,
                        })}
                    >
                        <a
                            href={`${url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="font-normal rounded px-8 py-4 bg-gray-dark hover:bg-black transition shadow hover:shadow-lg text-white text-display text-base-display lg:mx-4 mt-2 block w-full lg:w-auto md:inline-block"
                        >
                            Pledge to Your Team
                        </a>
                        <button
                            type="button"
                            data-mp-school-id={schoolId}
                            data-skip-team-selection
                            className="js-cta-manage rounded px-8 py-4 bg-gray-dark hover:bg-black transition shadow hover:shadow-lg text-white text-display text-base-display lg:mx-4 mt-2 block w-full lg:w-auto md:inline-block"
                        >
                            Join as a Captain
                        </button>
                        <button
                            type="button"
                            data-mp-school-id={schoolId}
                            data-skip-team-selection
                            className="js-cta-fundraise rounded px-8 py-4 bg-gray-dark hover:bg-black transition shadow hover:shadow-lg text-white text-display text-base-display lg:mx-4 mt-2 block w-full lg:w-auto md:inline-block"
                        >
                            Get Involved as a Student
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeaderboardResult;
