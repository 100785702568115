import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken } from 'axios';
import $ from 'jquery';
import cx from 'classnames';

import { apiUrl } from '../../utils';
import Result from './Result';

const ContributionLeaderboard = ({
    start,
    end,
    donationMinimum,
    pledgeMinimum,
    pageSize,
    showLoadMore,
}) => {
    const reducer = (state, action) => {
        switch (action.type) {
            case 'request':
                return {
                    ...state,
                    error: null,
                    loading: true,
                };

            case 'response':
                return {
                    ...state,
                    loading: false,
                    results: state.results.concat(action.payload),
                    canLoadMore: action.payload.length === pageSize,
                };

            case 'error':
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };

            case 'load-more':
                return {
                    ...state,
                    page: state.page + 1,
                };

            default:
                throw new Error(`No handler for action "${action.type}"`);
        }
    };

    const [state, dispatch] = useReducer(reducer, {
        results: [],
        error: null,
        loading: true,
        canLoadMore: false,
        page: 0,
    });

    useEffect(() => {
        let cancel;

        const base = apiUrl('/contribution-leaderboard');
        const params = {
            limit: pageSize,
            skip: pageSize * state.page,
        };

        if (start) params.start = start;
        if (end) params.end = end;
        if (donationMinimum) params.donationMinimum = donationMinimum;
        if (pledgeMinimum) params.pledgeMinimum = pledgeMinimum;

        dispatch({ type: 'request' });

        axios
            .get(`${base}?${$.param(params)}`, {
                cancelToken: new CancelToken(c => {
                    cancel = c;
                }),
            })
            .then(response => dispatch({ type: 'response', payload: response.data }))
            .catch(err => {
                const error = err.response.data;
                console.error('API Error:', error);
                dispatch({ type: 'error', payload: error.message || error });
            });

        return cancel;
    }, [state.page]);

    const { results, loading, canLoadMore } = state;
    const shouldShowMore = canLoadMore && showLoadMore && !loading;
    const noResults = !loading && !results.length;

    return (
        <>
            {noResults && (
                <p className="my-12 text-center text-display text-lg-display text-gray-light">
                    No results found.
                </p>
            )}
            {!!results.length && (
                <div>
                    <div className="flex uppercase font-bold tracking-wide text-xs text-gray-light">
                        <div className="hidden md:block w-24">Rank</div>
                        <div className="flex-4 px-8">School</div>
                        <div className="flex-no-shrink flex-1 px-8 text-center">Contributions</div>
                        <div className="flex-no-shrink px-8">
                            <i className="fal fa-plus-circle fa-fw fa-2x text-xl invisible" />
                        </div>
                    </div>
                    {results.map((result, i) => (
                        <Result key={result.id} rank={i + 1} {...result} />
                    ))}
                </div>
            )}
            <div className="mt-8 text-center">
                {loading && (
                    <span className="text-display text-lg-display text-gray-light inline-block py-4">
                        <i
                            className={cx('far fa-spinner fa-spin text-lg mr-4', {
                                hidden: !loading,
                            })}
                        />{' '}
                        Loading
                    </span>
                )}
                {shouldShowMore && (
                    <button
                        className={cx(
                            'text-display text-base-display rounded px-8 py-4 bg-gray-dark hover:bg-black transition shadow hover:shadow-lg text-white w-full md:w-auto md:inline-block',
                            {
                                'opacity-60 pointer-events-none': loading,
                            }
                        )}
                        onClick={() => dispatch({ type: 'load-more' })}
                        type="button"
                    >
                        View More
                    </button>
                )}
            </div>
        </>
    );
};

ContributionLeaderboard.propTypes = {
    start: PropTypes.string,
    end: PropTypes.string,
    donationMinimum: PropTypes.number,
    pledgeMinimum: PropTypes.number,
    pageSize: PropTypes.number,
    showLoadMore: PropTypes.bool,
};

ContributionLeaderboard.defaultProps = {
    start: null,
    end: null,
    donationMinimum: null,
    pledgeMinimum: null,
    pageSize: 10,
    showLoadMore: false,
};

export default ContributionLeaderboard;
