import $ from 'jquery';
import React from 'react';
import { render } from 'react-dom';
import { apiUrl } from '../utils';
import TeamList from '../components/TeamList';

export default class AwardsView {
    ui = {
        first: $('.js-aa-first-team'),
        second: $('.js-aa-second-team'),
        third: $('.js-aa-third-team'),
    };

    constructor({ api }) {
        if (!api) throw new Error('AwardsView requires API instance');
        this.api = api;

        this.renderAwards();
    }

    async renderAwards() {
        if (!this.ui.first.length && !this.ui.second.length && !this.ui.third.length) return;

        const firstTierTeams = [];
        const secondTierTeams = [];
        const thirdTierTeams = [];

        const leaderboard = await $.get(apiUrl('/leaderboard'));

        leaderboard.forEach(x => {
            const amountRaised = Number(x.estimated_amount_raised);
            if (amountRaised >= 5000) return firstTierTeams.push(x);
            if (amountRaised >= 2500) return secondTierTeams.push(x);
            if (amountRaised >= 1000) return thirdTierTeams.push(x);
            return null;
        });

        render(<TeamList teams={firstTierTeams} />, this.ui.first.get(0));
        render(<TeamList teams={secondTierTeams} />, this.ui.second.get(0));
        render(<TeamList teams={thirdTierTeams} />, this.ui.third.get(0));
    }
}
