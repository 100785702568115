import $ from 'jquery';
import qs from 'querystring';
import React from 'react';
import { render } from 'react-dom';
import Thanks from '../components/Thanks';

export default class ThanksView {
    ui = {
        container: $('.js-thanks'),
    };

    constructor() {
        this.renderThanks();
    }

    renderThanks() {
        // Not on thanks page
        if (!this.ui.container.length) return;

        // Don't have necessary data
        const search = window.location.search.replace('?', '');
        const { mp_school_id: schoolId, token } = qs.parse(search);

        if (!schoolId) {
            window.location = '/';
            return;
        }

        // Render it
        render(<Thanks schoolId={schoolId} token={token} />, this.ui.container.get(0));
    }
}
