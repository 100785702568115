import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Modal from './Modal';

const ProcessModal = ({ onClose, background, theme, children }) => (
    <Modal size="lg" onClose={onClose}>
        <div
            style={{ height: 600 }}
            className={cx('relative max-h-full flex flex-col', {
                'bg-yellow': theme === 'yellow',
                'bg-black': theme === 'dark',
                'bg-gray': theme === 'gray',
            })}
        >
            <img
                className="absolute pin w-full h-full object-cover opacity-10 z-0 saturate-0"
                src={background}
                alt=""
            />
            <div className="relative p-8 md:p-16 z-10 flex flex-col h-full">
                <button
                    className="text-white text-xl absolute pin-r pin-t -mt-6 -mr-6"
                    type="button"
                    onClick={onClose}
                >
                    <i className="fas fa-times-circle fa-fw" />
                </button>
                {children}
            </div>
        </div>
    </Modal>
);

ProcessModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    background: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(['yellow', 'dark', 'gray']).isRequired,
    children: PropTypes.node,
};

ProcessModal.defaultProps = {
    children: null,
};

export default ProcessModal;
