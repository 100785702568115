import React from 'react';
import PropTypes from 'prop-types';
import ProcessModal from './ProcessModal';
import ProcessSimple from './ProcessSimple';

const WelcomeBack = ({ theme, background, onClose, url }) => (
    <ProcessModal theme={theme} onClose={onClose} background={background}>
        <ProcessSimple
            headline="Welcome Back!"
            content="As a Captain, you play a key role in making a difference in your community. Continue on to rally support for your campaign!"
            buttonLabel="Continue to Campaign"
            onButtonClick={() => window.open(url)}
        />
    </ProcessModal>
);

WelcomeBack.propTypes = {
    theme: PropTypes.oneOf(['yellow', 'dark', 'gray']).isRequired,
    background: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
};

WelcomeBack.defaultProps = {};

export default WelcomeBack;
