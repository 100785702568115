import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import get from 'lodash/get';
import numeral from 'numeral';

import { pledgeItUrl } from '../utils';

class LifetimeStats extends Component {
    static propTypes = {
        lifetimeTotal: PropTypes.number,
    };

    static defaultProps = {
        lifetimeTotal: window.__LIFETIME_TOTAL,
    };

    state = {
        hasLoaded: false,
        currentTotal: 0,
    };

    async componentDidMount() {
        const data = await $.get(pledgeItUrl(`/api-public/campaigns/stats?tags=program:tac19`));

        this.setState({
            currentTotal: get(data, 'overall.estimated_amount_raised', 0) / 100,
            hasLoaded: true,
        });
    }

    get formattedTotal() {
        const { hasLoaded, currentTotal } = this.state;
        const { lifetimeTotal } = this.props;

        if (!hasLoaded) return '-';
        return numeral(currentTotal + lifetimeTotal).format('$0,0');
    }

    render() {
        return (
            <div className="">
                <p className="font-bold text-yellow leading-tight" style={{ fontSize: '5.6rem' }}>
                    {this.formattedTotal}
                </p>
                <p
                    className="mt-1 text-black leading-tight font-display uppercase"
                    style={{ fontSize: '3.6rem' }}
                >
                    Raised for St. Jude
                </p>
            </div>
        );
    }
}

export default LifetimeStats;
