import React from 'react';
import PropTypes from 'prop-types';
import Player from 'react-player';
import cx from 'classnames';

const Video = ({ url, className, ...props }) => (
    <div className={cx('aspect-ratio-widescreen relative', className)}>
        <Player
            className="absolute pin"
            width="100%"
            height="100%"
            url={url}
            config={{
                youtube: { rel: 0, showinfo: 0 },
            }}
            controls
            {...props}
        />
    </div>
);

Video.propTypes = {
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Video.defaultProps = {
    className: '',
};

export default Video;
