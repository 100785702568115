import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

class Modal extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        onClose: PropTypes.func.isRequired,
        size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
    };

    static defaultProps = {
        size: 'lg',
    };

    constructor(props) {
        super(props);
        this.modal = createRef();
    }

    componentDidMount() {
        // Lock the body scroll
        document.body.classList.add('overflow-hidden');

        // Dismiss on escape keypress
        document.addEventListener('keydown', this.handleKeypress, false);
    }

    componentWillUnmount() {
        document.body.classList.remove('overflow-hidden');
        document.removeEventListener('keydown', this.handleKeypress, false);
    }

    // Close on escape
    handleKeypress = ({ key, keyCode }) => {
        const { onClose } = this.props;
        if (key === 'Escape' || key === 'Esc' || keyCode === 27) onClose();
    };

    render() {
        const { children, size, onClose } = this.props;
        return (
            <Fragment>
                <div
                    className="fixed pin w-screen h-screen bg-black-70 z-50 cursor-pointer"
                    onClick={onClose}
                />
                <div className="fixed z-50 pin p-12 flex justify-center items-center pointer-events-none">
                    <div
                        className={cx(
                            'shadow-lg max-w-full max-h-full flex flex-col pointer-events-auto cursor-auto',
                            {
                                'w-sm': size === 'sm',
                                'w-md': size === 'md',
                                'w-lg': size === 'lg',
                                'w-xl': size === 'xl',
                                'w-xxl': size === 'xxl',
                            }
                        )}
                        ref={this.modal}
                    >
                        {children}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Modal;
