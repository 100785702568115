import $ from 'jquery';

export default class SliderView {
    currentSlide = 0;

    constructor(el) {
        if (!el) throw new Error('SliderView requires element');
        this.el = $(el);

        this.ui = {
            slide: this.el.find('[slide]'),
            nav: this.el.find('[slider-navigation]'),
            link: this.el.find('.js-show-slide'),
            container: this.el.find('[slider-container]'),
            next: this.el.find('[slider-next]'),
            prev: this.el.find('[slider-prev]'),
        };

        this.renderNavigation();
        this.showSlide(0);

        this.ui.next.on('click', this.handleNextClick);
        this.ui.prev.on('click', this.handlePrevClick);
        this.ui.link.on('click', this.handleLinkClick);

        this.ui.slide.swipe({
            swipeLeft: this.handleNextClick,
            swipeRight: this.handlePrevClick,
        });
    }

    get size() {
        return this.ui.slide.length;
    }

    handleNextClick = () => {
        const next = this.currentSlide === this.size - 1 ? 0 : this.currentSlide + 1;
        this.showSlide(next);
    };

    handlePrevClick = () => {
        const prev = this.currentSlide === 0 ? this.size - 1 : this.currentSlide - 1;
        this.showSlide(prev);
    };

    handleLinkClick = ({ currentTarget }) => {
        this.showSlide(Number($(currentTarget).data('slide')));
    };

    renderNavigation() {
        if (this.size < 2) {
            this.ui.navigation.hide();
            this.ui.next.hide();
            this.ui.prev.hide();
            return;
        }

        this.ui.slide.each(i =>
            this.ui.nav.append(`<div class="js-show-slide c-slider__dot" data-slide="${i}"></div>`)
        );

        this.ui.nav.show();
    }

    showSlide(i) {
        this.ui.slide.eq(this.currentSlide).removeClass('-active');
        this.ui.link.eq(this.currentSlide).removeClass('-active');

        this.ui.slide.eq(i).addClass('-active');
        this.ui.link.eq(i).addClass('-active');

        this.currentSlide = i;
    }
}
