import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { apiUrl } from '../utils';
import ProcessModal from './ProcessModal';
import ProcessOverview from './ProcessOverview';
import TeamSelect from './TeamSelect/TeamSelect';
import ProcessRegister from './ProcessRegister';
import ProcessSimple from './ProcessSimple';

class FundraiseProcess extends Component {
    static propTypes = {
        theme: PropTypes.oneOf(['yellow', 'dark', 'gray']).isRequired,
        background: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        overview: PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            video: PropTypes.string.isRequired,
            learn_more_url: PropTypes.string.isRequired,
            learn_more_label: PropTypes.string.isRequired,
            continue_label: PropTypes.string.isRequired,
        }).isRequired,
        schoolId: PropTypes.string,
        skipTeamSelect: PropTypes.bool,
    };

    static defaultProps = {
        schoolId: null,
        skipTeamSelect: false,
    };

    state = {
        view: 'overview',
        team: null,
    };

    async componentDidMount() {
        const { skipTeamSelect, schoolId } = this.props;
        if (!skipTeamSelect) return;

        const params = $.param({ q: schoolId, limit: 1 });
        const result = await $.get(apiUrl(`/search/campaign?${params}`));
        this.setState(() => ({ team: result[0] }));
    }

    get canSkip() {
        const { skipTeamSelect } = this.props;
        const { team } = this.state;
        return skipTeamSelect && !!team;
    }

    renderView() {
        const { theme, overview, onClose, schoolId } = this.props;
        const { view, team } = this.state;
        switch (view) {
            case 'overview':
                return (
                    <ProcessOverview
                        title={overview.title}
                        content={overview.content}
                        video={overview.video}
                        moreLabel={overview.learn_more_label}
                        moreUrl={overview.learn_more_url}
                        continueLabel={overview.continue_label}
                        onContinue={() =>
                            this.setState(() => ({
                                view: this.canSkip ? 'register' : 'select-team',
                            }))
                        }
                    />
                );

            case 'select-team':
                return (
                    <TeamSelect
                        theme={theme}
                        backLabel="Back"
                        schoolId={schoolId}
                        onBack={() => this.setState(() => ({ view: 'overview' }))}
                        onContinue={data => this.setState(() => ({ team: data, view: 'register' }))}
                        continueLabel="Next"
                    />
                );

            case 'register':
                return (
                    <ProcessRegister
                        {...team}
                        title="Your Info"
                        backLabel="Back"
                        onBack={() =>
                            this.setState(() => ({
                                view: this.canSkip ? 'overview' : 'select-team',
                            }))
                        }
                        continueLabel="Join"
                        onContinue={({ token }) => {
                            if (token) {
                                window.location = `/thanks/?mp_school_id=${
                                    team.schoolId
                                }&token=${token}`;
                                return;
                            }

                            this.setState(() => ({ view: 'check-email' }));
                        }}
                    />
                );

            case 'check-email':
                return (
                    <ProcessSimple
                        title="Please check your email"
                        content="It looks like you have an existing Pledge It account, probably because
                        you’ve supported Touchdowns Against Cancer in the past. Please check your
                        email to confirm your account."
                        buttonLabel="Close this window"
                        onButtonClick={onClose}
                    />
                );

            default:
                return null;
        }
    }

    render() {
        const { theme, onClose, background } = this.props;

        return (
            <ProcessModal theme={theme} onClose={onClose} background={background}>
                {this.renderView()}
            </ProcessModal>
        );
    }
}

export default FundraiseProcess;
