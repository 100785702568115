import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const US_STATES = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
];

const LeaderboardControls = ({
    label,
    query,
    onQueryChange,
    stateFilter,
    onStateFilterChange,
    onSubmit,
    showSearch,
    showStateSelect,
}) => (
    <form
        className="md:flex items-center w-full"
        onSubmit={e => {
            e.preventDefault();
            onSubmit();
        }}
    >
        {showSearch && (
            <div className="relative shadow-lg border-b-4 border-yellow flex-grow">
                <input
                    className="p-4 pl-16 w-full text-display text-lg-display"
                    type="text"
                    value={query}
                    onChange={onQueryChange}
                    placeholder={label}
                />
                <i className="fas fa-search text-lg-display text-yellow absolute pin-t pin-l mt-5 ml-4" />
            </div>
        )}
        {showStateSelect && (
            <div className="mt-4 md:mt-0 md:ml-8">
                <Select
                    className="font-bold uppercase text-xs text-gray w-48 tracking-wide mx-auto md:mx-0 shadow-lg md:shadow-none"
                    value={stateFilter}
                    onChange={x => onStateFilterChange(x ? x.value : x)}
                    options={US_STATES.map(x => ({ value: x, label: x }))}
                    placeholder="All States"
                    searchable={false}
                />
            </div>
        )}
        <button type="submit" className="hidden">
            Submit
        </button>
    </form>
);

LeaderboardControls.propTypes = {
    label: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    onStateFilterChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    stateFilter: PropTypes.string,
    showSearch: PropTypes.bool.isRequired,
    showStateSelect: PropTypes.bool.isRequired,
};

LeaderboardControls.defaultProps = {
    stateFilter: null,
};

export default LeaderboardControls;
