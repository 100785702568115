import $ from 'jquery';
import weeklyLeaderboardTemplate from '../templates/weekly-leaderboard.hbs';

const PAGE_SIZE = 3;

export default class AwardsView {
    ui = {
        list: $('[challenge-list]'),
        weekOne: $('[data-leaderboard-one]'),
        weekTwo: $('[data-leaderboard-two]'),
        weekTwoB: $('[data-leaderboard-two-sub]'),
        weekThree: $('[data-leaderboard-three]'),
        pager: $('[data-pager]'),
    };

    currentPageWeekOne = 0;

    currentPageWeekTwo = 0;

    currentPageWeekTwoB = 0;

    currentPageWeekThree = 0;

    constructor({ api }) {
        if (!api) throw new Error('ChallengesView requires API instance');
        this.api = api;

        this.orderChallenges();

        this.renderWeekOneLeaderboard();
        this.renderWeekTwoLeaderboard();
        this.renderWeekTwoBLeaderboard();
        this.renderWeekThreeLeaderboard();

        this.ui.pager.on('click', this.handlePagerClick);
    }

    handlePagerClick = ({ currentTarget }) => {
        const target = $(currentTarget).data('week');

        switch (target) {
            case 1:
                this.currentPageWeekOne += 1;
                return this.renderWeekOneLeaderboard();

            case 2:
                this.currentPageWeekTwo += 1;
                return this.renderWeekTwoLeaderboard();

            case '2-sub':
                this.currentPageWeekTwoB += 1;
                return this.renderWeekTwoBLeaderboard();

            case 3:
                this.currentPageWeekThree += 1;
                return this.renderWeekThreeLeaderboard();

            default:
                throw new Error('No leaderboard matching pager:', target);
        }
    };

    orderChallenges() {
        const challenges = this.ui.list.children().get();

        challenges.sort((x, y) => {
            const orderX = $(x).data('order');
            const orderY = $(y).data('order');

            if (orderX < orderY) return -1;
            if (orderX > orderY) return 1;
            return 0;
        });

        this.ui.list.empty();
        challenges.forEach(challenge => this.ui.list.append(challenge));
    }

    async renderWeekOneLeaderboard() {
        if (!this.ui.weekOne.length) return;

        const leaderboard = await this.api.getWeeklyLeaderboard(1);
        const startIndex = this.currentPageWeekOne * PAGE_SIZE;

        this.ui.weekOne.append(
            weeklyLeaderboardTemplate({
                items: leaderboard.slice(startIndex, startIndex + PAGE_SIZE),
                startIndex: startIndex + 1,
            })
        );
    }

    async renderWeekTwoLeaderboard() {
        if (!this.ui.weekTwo.length) return;

        const leaderboard = await this.api.getWeeklyLeaderboard(2);
        const startIndex = this.currentPageWeekTwo * PAGE_SIZE;

        this.ui.weekTwo.append(
            weeklyLeaderboardTemplate({
                items: leaderboard.slice(startIndex, startIndex + PAGE_SIZE),
                startIndex: startIndex + 1,
            })
        );
    }

    async renderWeekTwoBLeaderboard() {
        if (!this.ui.weekTwoB.length) return;

        const leaderboard = await this.api.getWeekTwoSubLeaderboard();
        const startIndex = this.currentPageWeekTwoB * PAGE_SIZE;

        this.ui.weekTwoB.append(
            weeklyLeaderboardTemplate({
                items: leaderboard.slice(startIndex, startIndex + PAGE_SIZE),
                startIndex: startIndex + 1,
            })
        );
    }

    async renderWeekThreeLeaderboard() {
        if (!this.ui.weekThree.length) return;

        const leaderboard = await this.api.getWeeklyLeaderboard(3);
        const startIndex = this.currentPageWeekThree * PAGE_SIZE;

        this.ui.weekThree.append(
            weeklyLeaderboardTemplate({
                items: leaderboard.slice(startIndex, startIndex + PAGE_SIZE),
                startIndex: startIndex + 1,
            })
        );
    }
}
