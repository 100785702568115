import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import get from 'lodash/get';
import cx from 'classnames';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { apiUrl, pledgeItUrl, wpAPIUrl } from '../utils';

class Thanks extends Component {
    static propTypes = {
        schoolId: PropTypes.string.isRequired,
        token: PropTypes.string.isRequired,
    };

    static defaultProps = {};

    state = {
        team: null,
        actions: null,
    };

    async componentDidMount() {
        try {
            const { schoolId } = this.props;
            const [team, actions] = await Promise.all([this.fetchTeam(), this.fetchActions()]);

            if (!team) throw new Error(`No school with ID "${schoolId}" found.`);
            if (!actions) throw new Error('Error fetching page actions.');

            this.setState(() => ({ team, actions }));
        } catch (err) {
            console.error(err);
            window.location = '/';
        }
    }

    getActionUrl(action) {
        const { token } = this.props;
        const { team } = this.state;
        const { campaignId } = team;

        const tokenBase = token ? `/auth/token/${token}?target=` : '';

        switch (action) {
            case 'edit':
                return pledgeItUrl(`${tokenBase}/manage/campaigns/${campaignId}/edit`);
            case 'users':
                return pledgeItUrl(`${tokenBase}/manage/campaigns/${campaignId}/users`);
            case 'resources':
                return '/resource-center/captain/';
            default:
                return '';
        }
    }

    async fetchTeam() {
        const { schoolId } = this.props;
        const result = await $.get(apiUrl(`/search/campaign?q=${schoolId}&limit=1`));
        return result[0];
    }

    async fetchActions() {
        const result = await $.get(wpAPIUrl('/wp/v2/pages?slug=thanks'));
        return get(result, '0.acf.actions');
    }

    render() {
        const { team, actions } = this.state;

        if (!team) {
            return (
                <div className="text-center text-2xl text-gray-light my-32">
                    <i className="fa fa-spinner fa-spin" />
                </div>
            );
        }

        const { token } = this.props;
        const tokenBase = token ? `/auth/token/${token}?target=` : '';
        const campaignUrl = pledgeItUrl(`${tokenBase}/${team.campaignSlug}`);
        const campaignShareUrl = pledgeItUrl(`/${team.campaignSlug}`);
        const campaignDisplayUrl = campaignShareUrl.split('://')[1];

        return (
            <Fragment>
                <div className="mt-16 mb-24 max-w-md mx-auto">
                    <div className="text-center text-display text-xl-display">
                        <p className="text-base-display text-gray-dark mb-4">Your Campaign Page</p>
                        <a
                            className="inline-block rounded bg-yellow shadow hover:shadow-lg text-white py-3 px-8 font-normal transition"
                            href={campaignUrl}
                        >
                            {campaignDisplayUrl}
                        </a>
                    </div>
                    <div className="text-center text-display text-base-display styled-content my-16">
                        <div className="inline-flex items-center">
                            <p className="text-gray-dark">Start Sharing</p>
                            <TwitterShareButton
                                title="Pledge now to help defeat childhood cancer!"
                                className="ml-8 text-gray-dark hover:text-gray transition"
                                url={campaignShareUrl}
                            >
                                <i className="fab fa-twitter fa-2x" />
                            </TwitterShareButton>
                            <FacebookShareButton
                                className="ml-8 text-gray-dark hover:text-gray transition"
                                url={campaignShareUrl}
                            >
                                <i className="fab fa-facebook-f fa-2x" />
                            </FacebookShareButton>
                        </div>
                    </div>
                </div>

                <div className="max-w-sm md:max-w-xxl mx-auto flex flex-col md:flex-row justify-center items-stetch shadow-lg w-screen my-24">
                    {actions.map(action => (
                        <div key={action.action} className="w-full mx-auto md:w-1/3 md:max-w-none">
                            <a
                                className={cx(
                                    'block cursor-pointer md:aspect-ratio-square relative group cta',
                                    `cta--${action.theme}`
                                )}
                                href={this.getActionUrl(action.action)}
                            >
                                <img
                                    className="absolute pin w-full h-full object-cover saturate-0 z-0 cta-image"
                                    src={action.image}
                                    alt=""
                                />
                                <span className="block absolute pin w-full h-full z-10 opacity-80 group-hover:opacity-90 transition cta-background" />
                                <div className="relative z-20 md:absolute md:pin md:w-full md:h-full flex md:flex-col justify-center items-center text-left md:text-center px-8 py-16 lg:px-16">
                                    <div className="flex-grow md:flex-no-grow pr-4 md:pr-0 ">
                                        <p className="text-display text-xl-display lg:text-2xl-display cta-text">
                                            {action.text}
                                        </p>
                                        {action.redirects && (
                                            <p className="text-display text-xsm-display cta-text mt-4">
                                                You will be taken to PledgeIt.org
                                            </p>
                                        )}
                                    </div>
                                    <span className="flex-no-shrink md:mt-12 w-16 h-16 text-center inline-flex justify-center items-center text-lg rounded-full border-4 cta-arrow">
                                        <i className="fas fa-arrow-right" />
                                    </span>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    }
}

export default Thanks;
