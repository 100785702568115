import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ResultRow = ({ isActive, onClick, name, state, mascot }) => (
    <div
        onClick={onClick}
        className={cx(
            'flex-no-shrink flex text-left py-3 px-5 rounded hover:bg-black-40 cursor-pointer',
            {
                'bg-white hover:bg-white text-gray-dark': isActive,
            }
        )}
    >
        <span className="flex-grow pr-2 truncate">{name}</span>
        <span className="w-1/5 pr-2 truncate">{state}</span>
        <span className="w-1/3 pr-2 truncate">{mascot}</span>
    </div>
);

ResultRow.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    state: PropTypes.string,
    mascot: PropTypes.string,
};

ResultRow.defaultProps = {
    state: '',
    mascot: '',
};

export default ResultRow;
