import React from 'react';
import PropTypes from 'prop-types';
import ProcessModal from './ProcessModal';
import TeamSelect from './TeamSelect/TeamSelect';
import { pledgeItUrl } from '../utils';

const PledgeProcess = ({ schoolId, theme, onClose, background }) => (
    <ProcessModal theme={theme} onClose={onClose} background={background}>
        <TeamSelect
            theme={theme}
            backLabel="Back"
            schoolId={schoolId}
            onContinue={data => window.open(pledgeItUrl(`/${data.campaignSlug}`, 'team-campaign'))}
            continueLabel="Pledge Now"
            continueWillRedirect
        />
    </ProcessModal>
);

PledgeProcess.propTypes = {
    theme: PropTypes.oneOf(['yellow', 'dark', 'gray']).isRequired,
    background: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    schoolId: PropTypes.string,
};

PledgeProcess.defaultProps = {
    schoolId: null,
};

export default PledgeProcess;
