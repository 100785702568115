import $ from 'jquery';
import pick from 'lodash/pick';

const API_URL = 'https://pledgeit.org/api/partners/maxpreps';

export default class LeaderboardService {
    _cache = {};

    /**
     * Get the weekly leaderboard based on the week specified
     * @param {string} 	 endpoint 					- Endpoint URL for the AJAX request
     * @param {function} requestParams 				- Request parameters object
     * @param {number}   requestParams.limit		- The number of results that should be returned
     * @param {string}   requestParams.name			- The name of used for filtering
     * @param {string}   requestParams.rankBy		- Sort by "rank", "name", "state", "results"
     * @param {number}   requestParams.skip			- Number of records to skip for paging
     */
    async _makeRequest(endpoint, requestParams = {}, includeParams = false) {
        let url = endpoint;

        if (includeParams) {
            const urlParams = pick(requestParams, ['limit', 'name', 'rankBy', 'skip']);
            url += `?${$.param(urlParams)}`;
        }

        const cachedResponse = this._cache[url];

        // Return already-fetched data if available
        const response =
            cachedResponse ||
            (await $.ajax({
                url,
                method: 'get',
                crossDomain: true,
                dataType: 'json',
                contentType: 'text/plain',
            }));

        // Store the value in case its requested again
        if (!cachedResponse) this._cache[url] = response;

        // Trim response if needed
        const shouldTrim = !includeParams && response.length && requestParams.limit;
        const result = shouldTrim ? response.slice(0, requestParams.limit) : response;

        // Return the result
        return result;
    }

    /**
     * Get the weekly leaderboard based on the week specified
     * @param {number} 	 week 						- The week used to retrieve the leaderboard (1, 2 or 3 are accepted values)
     * @param {function} requestParams 				- Request parameters object
     * @param {string}   requestParams.limit		- The number of results that should be returned
     * @param {string}   requestParams.name			- The name of used for filtering
     * @param {string}	 requestParams.onSuccess	- Callback for when the API call finishes successfully
     * @param {string}   requestParams.rankBy		- Sort by "rank", "name", "state", "results"
     * @param {string}   requestParams.skip			- Number of records to skip for paging
     */
    async getWeeklyLeaderboard(week, requestParams = {}) {
        const weekNumber = [1, 2, 3].includes(Number(week)) ? Number(week) : 1;
        const displayProp = weekNumber === 1 ? 'fundraisers' : 'contributions';

        const leaderboard = this._formatLeaderboard(
            await this._makeRequest(
                `${API_URL}/tac17/weeklyLeaderboard/${weekNumber}`,
                requestParams
            )
        )
            .map(x => ({ ...x, displayValue: x[displayProp] }))
            .sort((x, y) => y.displayValue - x.displayValue);

        if (requestParams.onSuccess) requestParams.onSuccess(leaderboard);
        return leaderboard;
    }

    async getWeekTwoSubLeaderboard(requestParams = {}) {
        const leaderboard = this._formatLeaderboard(
            await this._makeRequest(
                `${API_URL}/cheerLeaderboard/user/59bc442e0c810c1b28839001`,
                requestParams
            )
        )
            .map(x => ({ ...x, displayValue: x.contributions }))
            .sort((x, y) => y.displayValue - x.displayValue);

        if (requestParams.onSuccess) requestParams.onSuccess(leaderboard);
        return leaderboard;
    }
}
