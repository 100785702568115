import Handlebars from 'handlebars-template-loader/runtime';
import isObject from 'lodash/isObject';
import pick from 'lodash/pick';
import moment from 'moment';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import $ from 'jquery';

import APIService from './services/APIService';
import hbsHelpers from './templates/helpers';
import MainView from './views/MainView';
import AwardsView from './views/AwardsView';
import ChallengesView from './views/ChallengesView';
import ThanksView from './views/ThanksView';
import WelcomeBack from './components/WelcomeBack';

import OverallStats from './components/OverallStats';
import LifetimeStats from './components/LifetimeStats';
import Leaderboard from './components/Leaderboard';
import FundraiserLeaderboard from './components/FundraiserLeaderboard';
import ContributionLeaderboard from './components/ContributionLeaderboard';

import 'jquery-touchswipe';
import './style/global.scss';
import { pledgeItUrl } from './utils';

// Register Handlebars helpers
Object.entries(hbsHelpers).forEach(([name, fn]) => Handlebars.registerHelper(name, fn));

// Store MP Data keys if provided
if (isObject(window.__MP_DATA)) {
    const expiration = moment.utc(window.__MP_DATA.utc_dt).add({ minutes: 30 });
    // Make sure it's within 30 minutes of using the link
    if (moment().isBefore(expiration)) {
        if (window.__MP_DATA.token) {
            const { campaign, token } = window.__MP_DATA;
            const el = document.querySelector('.js-messaging');
            const url = pledgeItUrl(`/auth/token/${token}?target=/${campaign}`);
            const manageCta = window.__CTA_DATA.find(x => x.action === 'manage');
            render(
                <WelcomeBack
                    theme={manageCta.theme}
                    background={manageCta.image}
                    url={url}
                    onClose={() => unmountComponentAtNode(el)}
                />,
                el
            );
        }

        Object.entries(
            pick(window.__MP_DATA, ['first_name', 'last_name', 'mp_school_id', 'email'])
        ).forEach(([key, value]) => localStorage.setItem(key, value));
    }
}

// Initialize API instance
const api = new APIService();

// Render Views
// eslint-disable-next-line no-unused-vars
const views = {
    main: new MainView({ api }),
    awards: new AwardsView({ api }),
    challenges: new ChallengesView({ api }),
    thanks: new ThanksView({ api }),
};

$('.js-lifetime-stats').each((i, elem) => render(<LifetimeStats />, elem));

$('.js-program-stats').each((i, elem) =>
    render(<OverallStats title={$(elem).data('title')} state={$(elem).data('state')} />, elem)
);

$('.js-leaderboard').each((i, elem) =>
    render(
        <Leaderboard
            title={$(elem).data('title')}
            query={$(elem).data('query')}
            state={$(elem).data('state')}
            pageSize={$(elem).data('page-size')}
            showSearch={$(elem).data('show-search')}
            showStateSelect={$(elem).data('show-state-select')}
            showLoadMore={$(elem).data('show-more')}
        />,
        elem
    )
);

$('.js-fundraiser-leaderboard').each((i, elem) =>
    render(
        <FundraiserLeaderboard
            title={$(elem).data('title')}
            query={$(elem).data('query')}
            state={$(elem).data('state')}
            pageSize={$(elem).data('page-size')}
            showSearch={$(elem).data('show-search')}
            showStateSelect={$(elem).data('show-state-select')}
            showLoadMore={$(elem).data('show-more')}
        />,
        elem
    )
);

$('.js-contribution-leaderboard').each((i, elem) =>
    render(
        <ContributionLeaderboard
            start={$(elem).data('start')}
            end={$(elem).data('end')}
            donationMinimum={$(elem).data('donation-minimum')}
            pledgeMinimum={$(elem).data('pledge-minimum')}
            pageSize={$(elem).data('page-size')}
            showLoadMore={$(elem).data('show-more')}
        />,
        elem
    )
);
