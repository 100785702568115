export const assetUrl = image => `/wp-content/themes/custom-theme/dist${image}`;

export const pledgeItUrl = uri => `https://pledgeit.org${uri}`;
// export const pledgeItUrl = uri => `https://staging.pldgit.com${uri}`;

export const apiUrl = uri => pledgeItUrl(`/api/programs/tac19${uri}`);

export const wpAPIUrl = uri => `/wp-json${uri}`;

export default { assetUrl };
