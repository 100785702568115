import $ from 'jquery';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import SliderView from './SliderView';
import Modal from '../components/Modal';
import Video from '../components/Video';
import PledgeProcess from '../components/PledgeProcess';
import FundraiseProcess from '../components/FundraiseProcess';
import ManageProcess from '../components/ManageProcess';

export default class MainView {
    ui = {
        slider: $('[slider]'),
        classToggle: $('.js-toggle-class'),
        messaging: $('.js-messaging'),
        videoTrigger: $('.js-overview-video'),
    };

    constructor({ api }) {
        if (!api) throw new Error('MainView requires API instance');
        this.api = api;
        this._ctaData = window.__CTA_DATA;

        this.ui.slider.each((i, elem) => new SliderView(elem));
        this.ui.classToggle.on('click', this.handleToggleClassClick);
        this.ui.videoTrigger.on('click', this.handleOverviewVideoClick);

        // Need live bindings for dymanic buttons
        $(document.body).on('click', '.js-cta-pledge', this.handleCTAPledgeClick);
        $(document.body).on('click', '.js-cta-fundraise', this.handleCTAFundraiseClick);
        $(document.body).on('click', '.js-cta-manage', this.handleCTAManageClick);
    }

    getCTAData = async cta => this._ctaData.find(x => x.action === cta);

    handleToggleClassClick = ({ currentTarget }) => {
        const selector = $(currentTarget).data('tc-selector');
        const className = $(currentTarget).data('tc-class');
        const target = selector === 'self' ? currentTarget : selector;

        $(target).toggleClass(className);
    };

    _renderComponent = component => render(component, this.ui.messaging.get(0));

    _clearComponent = () => unmountComponentAtNode(this.ui.messaging.get(0));

    handleOverviewVideoClick = ({ currentTarget }) =>
        this._renderComponent(
            <Modal size="md" onClose={this._clearComponent}>
                <Video url={$(currentTarget).data('video-url')} playing />
            </Modal>
        );

    handleCTAPledgeClick = async ({ currentTarget }) => {
        const schoolId = $(currentTarget).data('mp-school-id');
        const { image, theme } = await this.getCTAData('pledge');

        this._renderComponent(
            <PledgeProcess
                schoolId={schoolId}
                background={image}
                theme={theme}
                onClose={this._clearComponent}
            />
        );
    };

    handleCTAFundraiseClick = async ({ currentTarget }) => {
        const schoolId = $(currentTarget).data('mp-school-id');
        const skipTeamSelect = $(currentTarget).data('skip-team-selection');
        const { image, theme, overview } = await this.getCTAData('fundraise');

        this._renderComponent(
            <FundraiseProcess
                schoolId={schoolId}
                background={image}
                theme={theme}
                overview={overview}
                skipTeamSelect={skipTeamSelect}
                onClose={this._clearComponent}
            />
        );
    };

    handleCTAManageClick = async ({ currentTarget }) => {
        const schoolId = $(currentTarget).data('mp-school-id');
        const skipTeamSelect = $(currentTarget).data('skip-team-selection');
        const { image, theme, overview } = await this.getCTAData('manage');

        this._renderComponent(
            <ManageProcess
                schoolId={schoolId}
                background={image}
                theme={theme}
                overview={overview}
                skipTeamSelect={skipTeamSelect}
                onClose={this._clearComponent}
            />
        );
    };
}
