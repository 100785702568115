import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import ProcessModal from './ProcessModal';
import ProcessOverview from './ProcessOverview';
import TeamSelect from './TeamSelect/TeamSelect';
import { apiUrl, pledgeItUrl } from '../utils';

class FundraiseProcess extends Component {
    static propTypes = {
        theme: PropTypes.oneOf(['yellow', 'dark', 'gray']).isRequired,
        background: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        overview: PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            video: PropTypes.string.isRequired,
            learn_more_url: PropTypes.string.isRequired,
            learn_more_label: PropTypes.string.isRequired,
            continue_label: PropTypes.string.isRequired,
        }).isRequired,
        schoolId: PropTypes.string,
        skipTeamSelect: PropTypes.bool,
    };

    static defaultProps = {
        schoolId: null,
        skipTeamSelect: false,
    };

    state = {
        view: 'overview',
        team: null,
    };

    async componentDidMount() {
        const { skipTeamSelect, schoolId } = this.props;
        if (!skipTeamSelect) return;

        const params = $.param({ q: schoolId, limit: 1 });
        const result = await $.get(apiUrl(`/search/campaign?${params}`));
        this.setState(() => ({ team: result[0] }));
    }

    get canSkip() {
        const { skipTeamSelect } = this.props;
        const { team } = this.state;
        return skipTeamSelect && !!team;
    }

    renderView() {
        const { theme, overview, schoolId } = this.props;
        const { view, team } = this.state;

        switch (view) {
            case 'overview':
                return (
                    <ProcessOverview
                        title={overview.title}
                        content={overview.content}
                        video={overview.video}
                        moreLabel={overview.learn_more_label}
                        moreUrl={overview.learn_more_url}
                        continueLabel={
                            this.canSkip ? 'Become a fundraiser' : overview.continue_label
                        }
                        onContinue={() => {
                            if (this.canSkip) {
                                window.open(
                                    pledgeItUrl(`/${team.campaignSlug}/fundraise`, 'team-campaign')
                                );
                            } else {
                                this.setState(() => ({ view: 'select-team' }));
                            }
                        }}
                    />
                );

            case 'select-team':
                return (
                    <TeamSelect
                        theme={theme}
                        backLabel="Back"
                        schoolId={schoolId}
                        onBack={() => this.setState(() => ({ view: 'overview' }))}
                        onContinue={data =>
                            window.open(
                                pledgeItUrl(`/${data.campaignSlug}/fundraise`, 'team-campaign')
                            )
                        }
                        continueLabel="Become a fundraiser"
                        continueWillRedirect
                    />
                );

            default:
                return null;
        }
    }

    render() {
        const { theme, onClose, background } = this.props;

        return (
            <ProcessModal theme={theme} onClose={onClose} background={background}>
                {this.renderView()}
            </ProcessModal>
        );
    }
}

export default FundraiseProcess;
