import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import $ from 'jquery';
import cx from 'classnames';
import ResultMessage from './ResultMessage';
import ResultRow from './ResultRow';
import { apiUrl } from '../../utils';

class TeamSelect extends Component {
    static propTypes = {
        theme: PropTypes.oneOf(['yellow', 'dark', 'gray']).isRequired,
        continueLabel: PropTypes.string.isRequired,
        onContinue: PropTypes.func.isRequired,
        continueWillRedirect: PropTypes.bool,
        onBack: PropTypes.func,
        backLabel: PropTypes.string,
        schoolId: PropTypes.string,
    };

    static defaultProps = {
        continueWillRedirect: false,
        backLabel: 'Back',
        onBack: null,
        schoolId: null,
    };

    state = {
        loading: false,
        query: '',
        results: null,
        selected: null,
        preselectedSchool: this.props.schoolId || localStorage.getItem('mp_school_id'), // eslint-disable-line react/destructuring-assignment
    };

    async componentDidMount() {
        const { preselectedSchool } = this.state;
        if (!preselectedSchool) return;

        // Search for a matching campaign record
        this.setState(() => ({ loading: true }));
        const results = await this._getResults({ query: preselectedSchool, limit: 1 });

        // If no results were found with given school ID, wipe it out and allow
        // the user to search for their school
        if (!results.length) {
            this.setState(() => ({
                loading: false,
                preselectedSchool: null,
            }));
            return;
        }

        // Update the form to look like the school was searched for,
        // preselecting the campaign matching the stored school ID
        const { schoolName, schoolMascot, schoolState, campaignId } = results[0];
        this.setState(() => ({
            loading: false,
            query: `${schoolName} ${schoolMascot} (${schoolState})`,
            selected: campaignId,
            results,
        }));
    }

    _getResults = ({ query, limit = 25 }) => {
        const params = $.param({ q: query, limit });
        return $.get(apiUrl(`/search/campaign?${params}`));
    };

    handleQueryChange = ({ currentTarget }) => {
        this.setState(() => ({ query: currentTarget.value }));
        this.handleQuerySubmit();
    };

    // eslint-disable-next-line react/sort-comp
    handleQuerySubmit = debounce(
        async e => {
            if (e) e.preventDefault();

            // Reset results & selected school
            this.setState(() => ({
                loading: true,
                results: null,
                selected: null,
            }));

            const { query } = this.state;

            // Form was cleared out
            if (!query) {
                this.setState(() => ({ loading: false }));
                return;
            }
            // Fetch & update results
            const results = await this._getResults({ query });

            this.setState(() => ({
                results: results.filter(x => !!x.schoolName),
                loading: false,
            }));
        },
        250,
        { trailing: true }
    );

    handleContinueClick = () => {
        const { onContinue } = this.props;
        const { results, selected } = this.state;
        const team = results.find(x => x.campaignId === selected);

        localStorage.setItem('mp_school_id', team.schoolId);
        return onContinue(team);
    };

    render() {
        const { theme, continueLabel, continueWillRedirect, onBack, backLabel } = this.props;
        const { loading, query, results, selected } = this.state;

        const hasNoResults = results && !results.length;
        const hasResults = results && !!results.length;

        const themeTextClassName = theme === 'yellow' ? 'text-gray-dark' : 'text-yellow';
        const themeBorderClassName = theme === 'yellow' ? 'border-gray-dark' : 'border-yellow';
        const themeSubmitClassName = theme === 'yellow' ? 'btn--gray-dark' : 'btn--yellow';

        return (
            <div className="flex flex-col h-full">
                <div className="flex flex-col flex-1 overflow-auto">
                    <div className="md:flex md:-ml-24 text-center lg:text-left items-center flex-no-shrink">
                        <form
                            className="md:pl-24 md:min-w-1/2 flex-1"
                            onSubmit={this.handleQuerySubmit}
                        >
                            <div className="relative">
                                <input
                                    className={cx(
                                        'bg-transparent text-white placeholder-white-40 py-3 pl-16 pr-4 border-2 w-full text-display text-base-display',
                                        themeBorderClassName
                                    )}
                                    type="text"
                                    placeholder="Find your team"
                                    onChange={this.handleQueryChange}
                                    value={query}
                                    autoFocus
                                />
                                <i
                                    className={cx(
                                        'fa fa-search absolute pin-t pin-l ml-6 mt-4 text-lg',
                                        themeTextClassName
                                    )}
                                />
                            </div>
                            <button className="hidden" type="submit">
                                Search
                            </button>
                        </form>
                    </div>
                    <div
                        className={cx(
                            'flex-grow text-display text-base-display text-white py-6 md:py-12',
                            { 'flex flex-col justify-center': !hasResults }
                        )}
                    >
                        {hasResults && (
                            <div
                                className={cx(
                                    'flex-no-shrink text-left flex text-display text-xs-display px-5 mb-2',
                                    themeTextClassName
                                )}
                            >
                                <span className="flex-grow">School</span>
                                <span className="w-1/5">State</span>
                                <span className="w-1/3">Mascot</span>
                            </div>
                        )}
                        {!query && (
                            <ResultMessage>Type your school name in the field above</ResultMessage>
                        )}
                        {loading && (
                            <ResultMessage>
                                <i className="fa fa-loading fa-spinner fa-spin" />
                            </ResultMessage>
                        )}
                        {hasNoResults && (
                            <ResultMessage className={themeTextClassName}>
                                No matching schools.
                            </ResultMessage>
                        )}
                        {hasResults &&
                            results.map(result => (
                                <ResultRow
                                    key={result.campaignId}
                                    isActive={result.campaignId === selected}
                                    onClick={() =>
                                        this.setState(() => ({ selected: result.campaignId }))
                                    }
                                    name={result.schoolName}
                                    state={result.schoolState}
                                    mascot={result.schoolMascot}
                                />
                            ))}
                    </div>
                </div>
                <div className="flex-no-shrink md:-ml-24 flex flex-col-reverse md:flex-row text-center">
                    {onBack && (
                        <div className="hidden lg:block md:pl-24 md:min-w-1/2 md:flex-1">
                            <button
                                className={cx('btn block w-full', {
                                    'btn--gray-dark': theme === 'gray',
                                    'btn--gray': theme === 'dark',
                                })}
                                type="button"
                                onClick={onBack}
                            >
                                {backLabel}
                            </button>
                        </div>
                    )}
                    <div className="mb-4 md:mb-0 md:pl-24 md:min-w-1/2 flex-1">
                        <button
                            className={cx('btn block w-full', themeSubmitClassName, {
                                'opacity-40 pointer-events-none': !selected,
                            })}
                            type="button"
                            onClick={this.handleContinueClick}
                        >
                            {continueLabel}
                            {continueWillRedirect && (
                                <i className="far fa-external-link-square-alt fa-sm ml-4" />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamSelect;
