import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import $ from 'jquery';
import phone from 'phone';
import { apiUrl } from '../utils';

yup.addMethod(yup.string, 'phone', function validatePhone(message) {
    return this.test({
        name: 'phone',
        exclusive: true,
        message: message || 'Must be a valid phone number',
        test: value => (value ? !!phone(value).length : true),
    });
});

class ProcessRegister extends Component {
    initialValues = {
        firstName: localStorage.getItem('first_name') || '',
        lastName: localStorage.getItem('last_name') || '',
        email: localStorage.getItem('email') || '',
        phone: localStorage.getItem('phone') || '',
    };

    validate = yup.object().shape({
        firstName: yup.string().required('This field is required'),
        lastName: yup.string().required('This field is required'),
        email: yup
            .string()
            .email('Invalid email address')
            .required('This field is required'),
        phone: yup.string().phone('Invalid phone number'),
    });

    static propTypes = {
        title: PropTypes.string.isRequired,
        onBack: PropTypes.func.isRequired,
        backLabel: PropTypes.string.isRequired,
        onContinue: PropTypes.func.isRequired,
        continueLabel: PropTypes.string.isRequired,
        schoolName: PropTypes.string.isRequired,
        schoolState: PropTypes.string.isRequired,
        schoolMascot: PropTypes.string.isRequired,
        campaignId: PropTypes.string.isRequired,
    };

    state = {
        error: false,
    };

    handleFormSubmit = async (values, { setSubmitting }) => {
        try {
            const { onContinue, campaignId } = this.props;

            this.setState(() => ({ error: false }));
            setSubmitting(true);

            onContinue(await $.post(apiUrl('/register'), { ...values, campaignId }));
        } catch (err) {
            setSubmitting(false);
            this.setState(() => ({ error: true }));
        }
    };

    render() {
        const {
            title,
            schoolName,
            schoolState,
            schoolMascot,
            onBack,
            backLabel,
            continueLabel,
        } = this.props;

        const { error } = this.state;

        return (
            <Formik
                initialValues={this.initialValues}
                isInitialValid={this.validate.isValidSync(this.initialValues)}
                validationSchema={this.validate}
                onSubmit={this.handleFormSubmit}
            >
                {({ isSubmitting, isValid }) => (
                    <Form className="flex flex-col flex-1 mt-4">
                        <div className="flex flex-col flex-1 overflow-auto pb-12">
                            <h1 className="text-center lg:text-left text-display font-normal text-2xl-display mb-4 text-yellow flex-no-shrink">
                                {title}
                            </h1>
                            <div className="flex-grow md:flex mt-8">
                                <div className="mb-8 md:mb-0 md:min-w-1/2 md:flex-1 flex md:flex-col">
                                    <dl className="text-display font-regular text-base-display pr-8 flex-grow md:flex-no-grow md:w-full truncate">
                                        <dt className="text-xs-display md:text-sm-display text-yellow">
                                            School
                                        </dt>
                                        <dd className="truncate mt-2 text-white">{schoolName}</dd>
                                    </dl>
                                    <dl className="text-display font-regular text-base-display pr-8  w-1/5 md:w-full md:mt-8 truncate">
                                        <dt className="text-xs-display md:text-sm-display text-yellow">
                                            State
                                        </dt>
                                        <dd className="mt-2 text-white">{schoolState}</dd>
                                    </dl>
                                    <dl className="text-display font-regular text-base-display pr-8  w-2/5 md:w-full md:mt-8 truncate">
                                        <dt className="text-xs-display md:text-sm-display text-yellow">
                                            Mascot
                                        </dt>
                                        <dd className="truncate mt-2 text-white">{schoolMascot}</dd>
                                    </dl>
                                </div>
                                <div className="md:pl-24 md:min-w-1/2 md:flex-1">
                                    <div className="relative">
                                        <Field
                                            className="bg-transparent text-white placeholder-white-40 py-3 pl-16 pr-4 border-2 w-full text-display text-base-display border-yellow"
                                            type="text"
                                            name="firstName"
                                            placeholder="First Name"
                                            autoFocus
                                        />
                                        <i className="far fa-user absolute pin-l pin-t ml-6 mt-4 text-lg text-yellow" />
                                        <ErrorMessage
                                            name="firstName"
                                            component="p"
                                            className="text-sm mt-2 text-white"
                                        />
                                    </div>
                                    <div className="relative mt-4">
                                        <Field
                                            className="bg-transparent text-white placeholder-white-40 py-3 pl-16 pr-4 border-2 w-full text-display text-base-display border-yellow"
                                            type="text"
                                            name="lastName"
                                            placeholder="Last Name"
                                        />
                                        <i className="far fa-user absolute pin-l pin-t ml-6 mt-4 text-lg text-yellow" />
                                        <ErrorMessage
                                            name="lastName"
                                            component="p"
                                            className="text-sm mt-2 text-white"
                                        />
                                    </div>
                                    <div className="relative mt-4">
                                        <Field
                                            className="bg-transparent text-white placeholder-white-40 py-3 pl-16 pr-4 border-2 w-full text-display text-base-display border-yellow"
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                        />
                                        <i className="far fa-envelope absolute pin-l pin-t ml-6 mt-4 text-lg text-yellow" />
                                        <ErrorMessage
                                            name="email"
                                            component="p"
                                            className="text-sm mt-2 text-white"
                                        />
                                    </div>
                                    <div className="relative mt-4">
                                        <Field
                                            className="bg-transparent text-white placeholder-white-40 py-3 pl-16 pr-4 border-2 w-full text-display text-base-display border-yellow"
                                            type="tel"
                                            name="phone"
                                            placeholder="Phone Number"
                                        />
                                        <i className="far fa-phone absolute pin-l pin-t ml-6 mt-4 text-lg text-yellow" />
                                        <ErrorMessage
                                            name="phone"
                                            component="p"
                                            className="text-sm mt-2 text-white"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-no-shrink md:-ml-24 flex flex-col-reverse md:flex-row text-center">
                            <div className="hidden lg:block md:pl-24 md:min-w-1/2 md:flex-1">
                                <button
                                    className="btn btn--gray block w-full"
                                    type="button"
                                    onClick={onBack}
                                >
                                    {backLabel}
                                </button>
                            </div>
                            <div className="mb-4 md:mb-0 md:pl-24 md:min-w-1/2 flex-1">
                                <button
                                    className={cx('btn btn--yellow block w-full', {
                                        'opacity-40 pointer-events-none': isSubmitting || !isValid,
                                    })}
                                    type="submit"
                                >
                                    {isSubmitting ? (
                                        <i className="fa fa-spinner fa-spin" />
                                    ) : (
                                        continueLabel
                                    )}
                                </button>
                            </div>
                        </div>
                        {error && (
                            <p className="text-gray text-sm font-medium mt-6 flex-no-shrink text-center">
                                There was an error submitting your information. Please try again
                                later.
                            </p>
                        )}
                    </Form>
                )}
            </Formik>
        );
    }
}

export default ProcessRegister;
