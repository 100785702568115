import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import numeral from 'numeral';

import { pledgeItUrl } from '../../utils';

class LeaderboardResult extends Component {
    static propTypes = {
        rank: PropTypes.number.isRequired,
        name: PropTypes.shape({
            first: PropTypes.string.isRequired,
            last: PropTypes.string,
        }).isRequired,
        username: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        campaign: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        ear: PropTypes.number,
    };

    static defaultProps = {
        ear: 0,
    };

    state = { isOpen: false };

    toggleOpen = () => this.setState(prev => ({ isOpen: !prev.isOpen }));

    render() {
        const { isOpen } = this.state;
        const { rank, name, username, slug, campaign, state, ear } = this.props;

        const url = pledgeItUrl(`/${slug}/@${username}`);

        return (
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center rounded-lg bg-white shadow-lg mt-4 text-display text-base-display transition text-gray-dark cursor-pointer group overflow-hidden font-thin"
            >
                <div
                    className={cx(
                        'transition self-stretch w-24 justify-center items-center text-white text-lg-display hidden md:flex',
                        {
                            'bg-yellow group-hover:bg-gray': !isOpen,
                            'bg-gray-dark': isOpen,
                        }
                    )}
                >
                    {rank}
                </div>
                <div className="flex-4 px-8 py-6 truncate">
                    {[name.first, name.last].join(' ').trim()}
                    <span className="block font-sans font-bold text-2xs text-gray-light mt-2">
                        {campaign.replace(/ Touchdowns Against Cancer 2019$/, '')}
                    </span>
                </div>
                <div className="hidden md:block flex-no-shrink flex-1 px-8 py-7 text-center">
                    {state}
                </div>
                <div className="flex-no-shrink flex-1 px-8 py-7 text-center">
                    {numeral(ear).format('$0,0')}
                </div>
                <div className="flex-no-shrink px-8 py-7">
                    <i className="transition fal fa-arrow-circle-right fa-fw fa-2x text-xl text-yellow group-hover:text-gray" />
                </div>
            </a>
        );
    }
}

export default LeaderboardResult;
