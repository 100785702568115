import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import get from 'lodash/get';
import numeral from 'numeral';

import { pledgeItUrl } from '../utils';

class OverallStats extends Component {
    static propTypes = {
        title: PropTypes.string,
        state: PropTypes.string,
    };

    static defaultProps = {
        title: '2019 Results',
        state: null,
    };

    state = {
        totalPerformance: '-',
        amountRaised: '-',
        totalContributors: '-',
    };

    async componentDidMount() {
        const { state } = this.props;

        const query = { tags: 'program:tac19' };
        if (state) query.state_prov = state;

        const data = await $.get(pledgeItUrl(`/api-public/campaigns/stats?${$.param(query)}`));

        const funds = get(data, 'overall.estimated_amount_raised', 0);
        const performanceData = get(data, 'performance_metrics', []).find(
            x => x.metric === 'touchdown' && x.activity === 'football'
        );
        const performance = get(performanceData, 'performance.total', 0);
        const contributors = get(data, 'contributors.total', 0);

        this.setState(() => ({
            totalPerformance: numeral(performance).format('0,0'),
            amountRaised: numeral(funds / 100).format('$0,0'),
            totalContributors: numeral(contributors).format('0,0'),
        }));
    }

    render() {
        const { title } = this.props;
        const { totalPerformance, amountRaised, totalContributors } = this.state;

        return (
            <div className="bg-black py-16 text-center mt-24">
                <div className="container-outer">
                    <h2 className="font-normal text-display text-yellow text-2xl-display">
                        {title}
                    </h2>
                    <div className="md:flex justify-between mt-16 max-w-lg mx-auto">
                        <div className="lg:px-8">
                            <p className="font-bold text-2xl-display text-white m-0 tracking-wide">
                                {totalPerformance}
                            </p>
                            <p className="text-xs text-yellow font-bold uppercase tracking-wide m-0 mt-4">
                                Touchdowns
                            </p>
                        </div>
                        <div className="mt-12 md:mt-0 lg:px-8">
                            <p className="font-bold text-2xl-display text-white m-0 tracking-wide">
                                {amountRaised}
                            </p>
                            <p className="text-xs text-yellow font-bold uppercase tracking-wide m-0 mt-4">
                                Est. Amount Raised
                            </p>
                        </div>
                        <div className="mt-12 md:mt-0 lg:px-8">
                            <p className="font-bold text-2xl-display text-white m-0 tracking-wide">
                                {totalContributors}
                            </p>
                            <p className="text-xs text-yellow font-bold uppercase tracking-wide m-0 mt-4">
                                Pledgers
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OverallStats;
