import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ResultMessage = ({ className, children }) => (
    <p className={cx('py-8 px-5 text-lg-display text-center', className)}>{children}</p>
);

ResultMessage.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

ResultMessage.defaultProps = {
    className: '',
    children: null,
};

export default ResultMessage;
